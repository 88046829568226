@import "../../global/variables"
.main-header
    background-color: $colors-light
    display: flex
    align-items: center
    height: 60px
    padding: 0 20px
    margin-bottom: 20px
    border-top-left-radius: 0px !important
    border-top-right-radius: 0px !important
    >.menu
        flex: 1
        text-align: end
