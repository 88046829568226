@import "../../global/variables"
.main-template
    display: flex
    flex: 1
    flex-direction: column
    >.content
        display: flex
        flex: 1
        background-color: $colors-background
        padding-bottom: 20px
        >.main
            flex: 1
            display: flex
            flex-direction: column
            padding: 0 20px
            >.screen-content
                flex: 1
                display: flex
                position: relative
