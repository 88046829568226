@import "../../global/variables"

.password-recovery
    display: flex
    flex: 1
    flex-direction: column
    height: 100%
    form
        display: contents
    .header
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        height: 80px
        .divider
            margin-top: 10px
            width: 75%
    .main
        height: 100%
        width: 75%
        display: flex
        flex: 1
        align-self: center
        align-items: center
        justify-content: center
        .content
            padding: 40px
            width: 270px
            padding: 20px 75px
            display: flex
            flex-direction: column
            align-items: center
        .footer
            width: 450px
            padding: 20px 75px
            text-align: center

    //         .confirm-image
    //             width: 100px
    //         .confirm-title
    //             margin: 20px 0
    //         .confirm-text
    //             margin-bottom: 40px
