#accept-term-wrapper
  #logo
    max-height: 4vh
    text-align: center
    img
      max-height: 100%
  #scroll-paper
    max-height: 60vh
    .MuiPaper-root
      overflow-y: auto
      max-height: 100%
      background-color: #F8F8F8
      text-align: justify
  #actions
    button
      float: right
