@import "../../global/variables"
.instance-settings-screen
    flex: 1
    display: flex
    flex-direction: column
    .screen-body
        flex-direction: column
        .instance-info,
        .instance-delete
            padding: 20px
            margin-top: 10px
            max-width: 800px
            .instance-delete-warning
                margin-top: 10px
                margin-bottom: 20px
        .row
            display: flex
            align-items: center
            .label
                flex: 0.3
            .field
                flex: 1
