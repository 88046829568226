@import "../../global/variables"
.main-footer
    background-color: $colors-light
    box-shadow: 0px 5px 10px $colors-dark
    display: flex
    height: 50px
    z-index: 11
    .left
        flex: 1
        display: flex
    .right
        flex: 1
        display: flex
        justify-content: flex-end
    .link
        padding: 0 10px
        color: $colors-royal
    .copyright
        height: 100%
        display: flex
        align-items: center
        padding: 0 10px
    .icon
