@import "../../global/variables"

.delete-card
    .content
        width: 300px
        .title
            margin-bottom: 20px
        .card-block-info
            display: flex
            .icon
                width: 60px
                margin: 0 10px
        .footer
            justify-content: flex-end
            display: flex
            margin-top: 20px
