@import "../../global/variables"

.user-delete-confirm-screen
    .content
        width: 400px
        .alert-text
            color: $colors-alert
            font-size: 16px
        // .content
        //     width: 300px
        //     .title
        //         margin-bottom: 20px
        .footer
            justify-content: flex-end
            display: flex
            margin-top: 20px
            .button
                margin-left: 10px
        .feedback-options
            margin-top: 30px
            margin-bottom: 30px
            .feedback-item
                width: 100%
                background-color: $colors-gray-light
                align-items: center
                justify-content: flex-start
                padding: 5px 20px
                margin-bottom: 5px
                border-radius: 100px
                .icon
                    font-size: 16px
                .text
                    font-size: 16px
                    font-weight: bold
                    margin-left: 20px
                &.active
                    background-color: $colors-blue
                    .text
                        color: $colors-light
