@import "../../global/variables"
.main-sidebar
    background-color: $colors-light
    width: 250px
    z-index: 10
    display: flex
    flex-direction: column
    align-items: center
    border-top-left-radius: 0px !important
    border-top-right-radius: 0px !important
    .logo
        width: 100px
        margin-top: 20px
        margin-bottom: 40px
    .menu
        flex: 1
        align-items: center
        width: 100%
        .menu-button
            padding-left: 40px
    .call-to-plan
        flex-direction: column
        padding: 5px
        padding-top: 25px
        margin: 20px
        background: linear-gradient(to right, $colors-blue, $colors-royal)
        color: $colors-light
        border-radius: 20px
        .now
            flex: 1
            color: $colors-blue
            font-weight: bold
            margin: 10px
            background-color: $colors-light
            border-radius: 20px
            padding: 10px
            width: calc( 100% - 30px )
