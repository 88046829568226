@import "../../global/variables"

.login-screen
    display: flex
    flex: 1
    flex-direction: column
    height: 100%
    .header
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        height: 80px
        .divider
            margin-top: 10px
            width: 75%
    .main
        height: 100%
        width: 100%
        display: flex
        flex: 1
        align-items: center
        justify-content: center
        .content
            padding: 20px 75px
            width: 270px
            .tabs
                margin-bottom: 20px
            .divider
                height: 2px
                background-color: $colors-dark
                text-align: center
                margin: 10px 0
                span
                    position: absolute
                    margin-top: -15px
                    margin-left: -10px
                    padding: 5px
                    background-color: $colors-light
            .keep-connected-wrapper
                display: flex
                justify-content: center
