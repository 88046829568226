@import "../../global/variables"
.instance-screen
    flex: 1
    display: flex
    flex-direction: column
    .screen-body
        flex-direction: column
        >.page-header
            display: flex
            flex-direction: row
            margin-bottom: 20px
            .title
                flex: 1
        .MuiDataGrid-row
            cursor: pointer
        .MuiDataGrid-cell
            &:focus
                outline: none
        .empty-img
            align-self: center
            margin-top: 100px
        .seta-img
            // align-self: right
            width: 150px
#new-batch-wrapper
    width: 100%
    margin-bottom: 20px
    button
        float: right

.MuiChip-deleteIcon
    color: #333333 !important
