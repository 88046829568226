@import "../../global/variables"
.user-settings-screen
    flex: 1
    display: flex
    flex-direction: column
    .screen-body
        flex-direction: column
        .person-info
            padding: 20px
            margin-top: 10px
            max-width: 800px
            .row
                display: flex
                align-items: center
                .label
                    flex: 0.3
                .field
                    flex: 1
                    .disabled-edit
                        display: flex
                        align-items: center
                        .text
                            width: 350px
                    .enabled-edit
                        display: inline
                        width: 100%
                        .input
                            width: 350px

        .delete-info
            padding: 20px
            margin-top: 10px
            max-width: 800px
        // .header
        //     display: flex
        //     flex-direction: row
        //     margin-bottom: 20px
        //     .title
        //         flex: 1
        // .main
        //     width: 100%
        //     display: flex
        //     justify-content: center
        //     .links
        //         display: flex
        //         flex-direction: row
        //         justify-content: center
        //         margin-bottom: 10px
        //     .file-upload-wrapper
        //         width: 100%
        //         display: flex
        //         align-items: center
        //         justify-content: center
.premium-color
    color: $colors-orange
