@import "../../global/variables"
.screen
    flex: 1
    display: flex
    flex-direction: column
    .screen-header
        padding: 10px 20px
    .screen-body
        flex: 1
        display: flex
        margin-top: 20px
        padding: 20px
    #support-fab
        width: fit-content
        position: fixed
        left: 100%
        top: 90vh
        transform: translateX(-100%)
        margin: -8px
        z-index: 10000
