@import "../../global/variables"
.analysis-result-course
    // .accordion
    margin-top: 10px
    margin-bottom: 10px
    border-radius: 10px
    &::before
        display: none
    .accordion-content
        min-height: 80px
        >.loading
            position: absolute
            margin: 20px
            left: calc(50% - 24px)
    // .recommendations
        // .recommendation-card
        //     background-color: lighten($colors-alert-dark, 20) !important
        //     margin: 5px
        //     >.recommendation-card-header
        //         padding: 10px
        //     >.recommendation-card-body
        //         background-color: lighten($colors-green, 30) !important
        //         padding: 10px
        //         min-height: 100px
        //         .recommendation-card-body-title
        //             color: $colors-green-dark
        //             display: flex
        //             align-items: center
        //             .icon
        //                 margin-right: 10px
