@import "../../global/variables"
.main-template
    >.content
        overflow-y: auto
    >.main
        overflow-y: scroll
.analysis-result-screen
    flex: 1
    display: flex
    flex-direction: column
    max-width: 1000px
    .content
        position: relative
        min-height: 160px
        .loading
            position: absolute
            margin: 50px
            left: calc(50% - 24px)
    .red
        color: $colors-alert
    .color-bar
        width: 5px
        height: 100%
        border-radius: 100px
        left: 0
    .MuiFab-root
        &.white
            background-color: #ffffff !important
            box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%) !important
    .screen-body
        flex-direction: column
        >.page-header
            .title
                margin-bottom: 20px
        .result-wrapper
            margin-top: 20px
            .summary
                .title
                    color: $colors-royal
    // .summary
    //     padding: 20px
    //     .summary-item
    //         display: flex
    //         align-items: center
    //         border-right: 1px solid $colors-gray
    //         padding: 10px
    //         justify-content: center
    //         &:last-child
    //             border-right: none
    //         .icon
    //             margin-right: 5px

    // .recommendations
    //     .recommendation-card
    //         background-color: lighten($colors-alert-dark, 20) !important
    //         margin: 5px
    //         >.recommendation-card-header
    //             padding: 10px
    //         >.recommendation-card-body
    //             background-color: lighten($colors-green, 30) !important
    //             padding: 10px
    //             min-height: 100px
    //             .recommendation-card-body-title
    //                 color: $colors-green-dark
    //                 display: flex
    //                 align-items: center
    //                 .icon
    //                     margin-right: 10px
        // .card-wrapper
        //     height: 85%
        //     .info
        //         color: #44B51D
        // .MuiCardHeader-root
        //     background-color: #FFDBDB
        // .MuiCardContent-root
        //     background: #E0F2F1
        //     height: 100%
    .donut-text
        position: relative
        bottom: 142px
    .MuiCard-root
        height: 100%
