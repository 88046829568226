@import "../../global/variables"
.instance-button
    display: flex
    flex-direction: column
    height: 150px
    width: 200px
    margin: 10px !important
    position: relative
    background-color: $colors-light
    z-index: 1
    &:nth-child(3n)
        break-after: always
    .button
        flex: 1
        display: flex
        flex-direction: column
        align-items: flex-start
        border-radius: 15px !important
        box-shadow: 4px 4px 15px -5px $colors-dark
        >.body
            flex: 1
            width: 75%
            >.title
                text-align: left
                padding: 10px
        >.footer
            display: flex
            flex-direction: column
            width: 75%
            text-align: left
            padding-bottom: 10px
            .title
                padding-left: 10px
            .token
                flex: 1
                padding-left: 10px
                overflow: hidden
                text-overflow: ellipsis
                white-space: nowrap
    >.settingsButton
        position: absolute
        top: 10px
        right: 10px
    >.copyButton
        position: absolute
        bottom: 10px
        right: 10px
