@import ../../global/variables
.metric-block
    position: relative
    width: 100%
    max-width: 100%
    .title
        font-size: 0.9em
        font-weight: 500
        // margin: 0 !important
        svg
            font-size: 1.4em
    .subtitle
        display: block
        margin: 10px 0
        line-height: 1
        min-height: 25px
    .classification-button
        position: relative
        padding-left: 10px
        margin-bottom: 2px
        width: 100%
        display: block
        text-align: inherit
        .color-bar
            position: absolute
            width: 5px
            height: 100%
            border-radius: 100px
            left: 0
        .title
            margin: 0 !important
        .value
            text-align: right
            &.high
                color: $colors-green
            &.low
                color: $colors-alert-dark
