@import "../../global/variables"
.invoices-screen
    flex: 1
    display: flex
    flex-direction: column
    // .screen-body
    //     background-image: url('./images/bg.png')
    //     background-repeat: no-repeat
    //     background-position: bottom right
    //     display: block
    //     .text-wrapper
    //         width: 600px
    //         margin-bottom: 30px
    //         .plan-name
    //             margin-bottom: 10px
    .screen-body
        flex-direction: column
        .tab-content
            margin-top: 40px
            max-width: 1000px

#starter-paper
    position: relative
    .MuiGrid-root
        z-index: 1
    &:after
        content: ' '
        background-image: url('./images/bg.png')
        background-repeat: no-repeat
        background-position: bottom right
        position: absolute
        z-index: 0
        width: 100%
        height: 100%
        background-size: contain
        top: 0
        left: 0
        bottom: 0
        opacity: 0.3
        right: 0
