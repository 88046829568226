@import "../../global/variables"

#dialog-premium
    .premium-color
        font-weight: bold
        color: #E5AC18
    .checkCircle
        color: #3B8FE4

.instances-screen
    flex: 1
    display: flex
    flex-direction: column
    .screen-body
        flex-direction: row
        flex-wrap: wrap
        align-content: baseline
        &:after
            content: ' '
            background-image: url('./images/bg.png')
            background-repeat: no-repeat
            background-position: bottom right
            position: absolute
            z-index: 0
            width: 100%
            height: 100%
            background-size: contain
            top: 0
            left: 0
            bottom: 0
            opacity: 0.3
            right: 0

    .add-instance
        display: flex
        flex-direction: column
        height: 150px
        width: 200px
        margin: 10px
        background: linear-gradient($colors-royal, $colors-blue)
        box-shadow: 2px 2px 8px $colors-dark
        color: $colors-light
        border-radius: 15px
        z-index: 1
