@import "../../global/variables"

.new-card
    .content
        width: 300px
        .title
            margin-bottom: 20px
            display: flex
            align-items: center
            .icon
                margin-right: 10px
        .expiration-separate
            display: inline
            padding: 5px
        .footer
            justify-content: flex-end
            display: flex
            margin-top: 20px
