@import "../../global/variables"

.confirm-email
    display: flex
    flex: 1
    flex-direction: column
    height: 100%
    .header
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        height: 80px
        .divider
            margin-top: 10px
            width: 75%
    .main
        height: 100%
        width: 100%
        display: flex
        flex: 1
        align-items: center
        justify-content: center
        .content
            padding: 40px
            width: 380px
            margin-top: -20%
            display: flex
            flex-direction: column
            align-items: center
            .confirm-image
                width: 100px
            .confirm-title
                margin: 20px 0
            .confirm-text
                margin-bottom: 40px
