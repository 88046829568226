@import "../../global/variables"

.edit-card
    .content
        width: 300px
        .title
            margin-bottom: 20px
            display: flex
            align-items: center
            .icon
                margin-right: 10px
        .card-block-info
            display: flex
            align-items: center
            .icon
                width: 60px
                margin: 0 10px
        .expiration-separate
            display: inline
            padding: 5px
        .footer
            justify-content: flex-end
            display: flex
            margin-top: 20px
