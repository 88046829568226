@import "../../global/variables"
.new-analysis-screen
    flex: 1
    display: flex
    flex-direction: column
    .screen-body
        flex-direction: column
        .header
            display: flex
            flex-direction: row
            margin-bottom: 20px
            .title
                flex: 1
        .main
            flex: 1
            width: 100%
            display: flex
            justify-content: center
            flex-direction: column
            .links
                display: flex
                flex-direction: row
                justify-content: center
                margin-bottom: 10px
            .file-upload-wrapper
                width: 100%
                display: flex
                align-items: center
                justify-content: center
