@import "../../global/variables"

.new-instance
    .content
        width: 300px
        .title
            margin-bottom: 20px
        .footer
            justify-content: flex-end
            display: flex
            margin-top: 20px
