$colors-light: #fff
$colors-dark: #333333
$colors-gray: #999999
$colors-gray-light: #EFEFEF
$colors-royal: #3B8FE4
$colors-blue: #3C3BE5
$colors-yellow: #E5AC18
$colors-purple: #7E0ACA
$colors-background: #F1F3F4
$colors-alert: #ff0000
$colors-alert-dark: #E43C3B
$colors-green: #3BE490
$colors-green-dark: #44B51D
$colors-orange: #E4913B

$font-primary: "Roboto Slab"
$font-secondary: "Roboto"

// $colors: ("light": #fff, "gray": #c4c4c4, "dark": #000)
